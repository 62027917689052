import React, { useEffect, memo, useState } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Aos from 'aos';

import { Link } from 'gatsby';




const AccordionItem = ({children, titulo = '', isOpen}) =>
{
    const [open, setOpen] = useState(isOpen);

    /* useEffect(() => {
        console.log('AccordionItem:','render');
        return () => {
            console.log('AccordionItem:','unmount');
        }
    }, []); */

    const openItem = () =>
    {
        setOpen(!open);
    }

    return (
        <div data-aos="fade-in" data-aos-duration="800" className="homeaccordion__item">
            <div className={open === true ? `homeaccordion__item__name` : `homeaccordion__item__name homeaccordion__item__closed`} onClick={openItem} role="presentation">
                <div className="ion android-arrow-dropdown-circle homeaccordion--arrow"></div>
                <h2>{titulo}</h2>
            </div>
            <div className="homeaccordion__item__text">
                {children}
            </div>
        </div>
    );
}



const onFocus = () =>
{
    //console.log('PaginaTermosEcondicoes:','onFocus');
    Aos.refresh();
}

const TempPaginaTermosEcondicoes = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaTermosEcondicoes:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaTermosEcondicoes:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="Termos e condições" />
            <SubHeader pagename="Termos e condições" bg="page-bg-termos-e-condicoes.jpg" />

            <div style={{marginBottom: 100}}></div>
            <div className="page__body page__privacy">
                <div className="page__content">

                    <AccordionItem titulo="Em breve" isOpen={false}>
                        Em breve
                    </AccordionItem>

                    <Space altura="20" />

                    <p className="link" data-aos="fade-in" data-aos-duration="1000"><Link to="/politica-de-privacidade/">Veja também a nossa política de privacidade</Link></p>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div>
        </>
    )
}

const PaginaTermosEcondicoes = memo(TempPaginaTermosEcondicoes);

export default PaginaTermosEcondicoes;